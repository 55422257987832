import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import { SEO, WorkExperience, Education, Skills, Awards } from '../organisms';
import { About, Intro } from '../molecules';
import { Layout } from '../templates';

const IndexPage = () => {
  const { dataJson: copy } = useStaticQuery(
    graphql`
      query MyQuery {
        dataJson {
          intro {
            title
            metaData {
              logoLeft
              logoRight
            }
            background {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }

          about {
            body
          }

          workExperience {
            icon
            title
            jobs {
              companyName
              duties
              endYear
              startYear
              title
            }
          }

          education {
            icon
            schools {
              courses
              degree
              endYear
              program
              schoolName
              startYear
              twoColumn
            }
            title
          }

          skills {
            heading {
              icon
              title
            }
            section1 {
              column1 {
                list
                title
              }
              column2 {
                list
                title
              }
              column3 {
                list
                title
              }
              column4 {
                list
                title
              }
              heading {
                icon
                title
              }
            }
            section2 {
              column1 {
                list
                title
              }
              column2 {
                list
                title
              }
              heading {
                icon
                title
              }
            }
          }

          awards {
            heading {
              icon
              title
            }
            list {
              description
              year
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <SEO
        title="Jamie Kelly - Software Engineer (Web)"
        keywords={[`jamie kelly`, `software engineer`, `san francisco`]}
      />
      <Intro {...copy.intro} />
      <About {...copy.about} />
      <WorkExperience {...copy.workExperience} />
      <Education {...copy.education} />
      <Skills {...copy.skills} />
      <Awards {...copy.awards} />
    </Layout>
  );
};

export default IndexPage;
